import React from "react";
import "./Widgets.css";
import InfoIcon from "@mui/icons-material/Info";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

function Widgets() {
  const newsArticle = (heading, subtitle) => (
    <div className="widgets__article">
      <div className="widgets__articleLeft">
        <FiberManualRecordIcon />
      </div>

      <div className="widgets__articleRight">
        <h4>{heading}</h4>
        <p>{subtitle}</p>
      </div>
    </div>
  );

  const footerMenu = (menuItem) => (
    <div className="widgets__footerMenu">
      <p>{menuItem}</p>
    </div>
  );

  return (
    <div className="widgets">
      <div className="widgets__header">
        <h2>LinkedIn News</h2>
        <InfoIcon />
      </div>

      {newsArticle("Greagori named Top CTO", "Top news - 9099 readers")}
      {newsArticle(
        "Coronavirus: US faces new variant",
        "Top news - 886 readers"
      )}
      {newsArticle("Tesla bumps in the road", "Cars & auto - 300 readers")}
      {newsArticle("Bitcoin drops to $21k", "Crypto - 8000 readers")}
      {newsArticle("Is Redux too good?", "Code - 123 readers")}
      {newsArticle("Amazon takes bite of Grubhub ", "Top news - 6503 readers")}
      <hr />
      <hr />
      <div className="widget__footer">
        {footerMenu("About")}
        {footerMenu("Accessibility")}
        {footerMenu("Help Center")}
        {footerMenu("Privacy & Terms")}
        {footerMenu("Ad Choices")}
        {footerMenu("Advertising")}
        {footerMenu("Business Services")}
        {footerMenu("Get the LinkedIn app")}
        {footerMenu("More")}
        <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/0/01/LinkedIn_Logo.svg/768px-LinkedIn_Logo.svg.png?20210504194946" />
      </div>
    </div>
  );
}

export default Widgets;
