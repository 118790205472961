import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyD-cHCUXTw9_dVbRPV--O8W5W-iVgcpAOw",
  authDomain: "linkedin-clone-c13bc.firebaseapp.com",
  projectId: "linkedin-clone-c13bc",
  storageBucket: "linkedin-clone-c13bc.appspot.com",
  messagingSenderId: "790344662965",
  appId: "1:790344662965:web:89b44f68af865a96bde3fc",
  measurementId: "G-PMFLR903CR",
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
const db = firebaseApp.firestore();
const auth = firebase.auth();

export { db, auth };
