import React from "react";
import { Avatar } from "@material-ui/core";
import { selectUser } from "./features/userSlice";
import { useSelector } from "react-redux";
import PeopleIcon from "@mui/icons-material/People";
import "./Sidebar.css";

function Sidebar() {
  const user = useSelector(selectUser);

  const recentItem = (topic) => (
    <div className="sidebar__recentItem">
      <span className="sidebar__hash">#</span>
      <p>{topic}</p>
    </div>
  );
  const groupItem = (group) => (
    <div className="sidebar__groupItem">
      <span className="sidebar__hash">
        <PeopleIcon fontSize="small" />
      </span>
      <p>{group}</p>
    </div>
  );
  return (
    <div className="sidebar">
      <div className="sidebar__top">
        <img
          src={
            "https://media-exp1.licdn.com/dms/image/C4D16AQEUAbUgwX8JVQ/profile-displaybackgroundimage-shrink_350_1400/0/1580747101739?e=1661990400&v=beta&t=kakb8jpU6vHMk6ke1Qp7UM72Jb70tqZ9UvQzBrzkBsk"
          }
          alt=""
        />
        <Avatar src={user.photoUrl} className="sidebar__avatar">
          {user.email[0]}
        </Avatar>
        <h2>{user.displayName}</h2>
        <h4>{user.email}</h4>
      </div>
      <div className="sidebar__stats">
        <div className="sidebar__stat">
          <p>Who viewed your profile</p>
          <div className="sidebar__statNumber">
            <p>2,453</p>
          </div>
        </div>
        <div className="sidebar__stat">
          <p>Impressions of your posts</p>
          <div className="sidebar__statNumber">
            <p>2,442</p>
          </div>
        </div>
      </div>
      <div className="sidebar__bottom">
        <p>Recent</p>
        {recentItem("reactjs")}
        {recentItem("programming")}
        {recentItem("nodejs")}
        {recentItem("softwareengineering")}
        {recentItem("design")}
        {recentItem("developer")}
        <br />
        <p>Groups</p>
        {groupItem("CXO - CIO/CTO Group")}
        {groupItem("React Programming")}
        {groupItem("Networking after work")}
        {groupItem("Software Engineering")}
      </div>
    </div>
  );
}

export default Sidebar;
